@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-top: 50px;
  margin-bottom: 50px;

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include extra-small-break {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}